/* You can add global styles to this file, and also import other style files */

@import 'variables';
@import 'oph-input';
@import 'oph-select';
@import 'full-calendar';
@import 'time-picker-ui';
@import './material/themes/themes';
@import './dialogs.scss';
@import '../assets/ophanello/css/ophanello.css';
@import '../assets/ophanello/css/animation.css';
@import './components/current-sked.scss';
@import './components/projects.scss';
@import './components/oph-autocomplete.scss';
@import './components/oph-expansion-panel.scss';
@import '~quill/dist/quill.snow.css';

//This might come back
// @font-face {
//   font-family: 'Asap Condensed';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 9px;
//   src:
//     local('Asap Condensed'),
//     url('../assets/fonts/Asap_Condensed/AsapCondensed-Medium.ttf') format('truetype');
// }

@font-face {
  font-family: 'Inter-Normal';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf');
}

// This is make sure the screen fits on mobile devices with variables url bars
// https://developer.chrome.com/blog/url-bar-resizing
.app-layout {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  font-family: 'Asap';
  letter-spacing: unset !important;
}

// This is the main container for each feature
.oph-feature-layout {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.oph-feature-padding {
  padding: var(--layout-margin);
}

.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.oph-scroll-bar::-webkit-scrollbar,
.oph-feature-layout::-webkit-scrollbar {
  width: 3px;
}

.oph-scroll-bar::-webkit-scrollbar-track,
.oph-feature-layout::-webkit-scrollbar-track {
  background-color: #c7c2b6;
  border-radius: 14px;
}

.oph-scroll-bar::-webkit-scrollbar-thumb,
.oph-feature-layout::-webkit-scrollbar-thumb {
  background-color: #7c655f;
  border-radius: 14px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb-10 {
  margin-bottom: 10px;
}

/********************************************************************
        Comment out to show debug messages for development
*********************************************************************/
.debug {
  display: none;
}

/********************************************************************
                        Z-Index Config
********************************************************************/
.modal {
  z-index: 1051;
}

// Material dropdown selects and other material overlays
.cdk-overlay-container {
  z-index: 1052;
}

.cdk-overlay-pane {
  &.skill-details-type {
    width: 420px;
    border: solid 1px #ccc;
    border-radius: 5px;
    background: #fefdf5;
    padding: 10px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    * {
      color: #7c655f;
    }

    .type-label {
      width: 100%;
    }

    .types-list {
      width: 100%;
      background: #f0ecdd;
      border-radius: 6px;
      padding: 12px 10px;

      .type-item {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #e2ded5;

        span {
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &:hover,
        &.create {
          background: #fefdf5;
          border-radius: 6px;
        }

        &.create {
          width: 100%;
          display: flex;
          gap: 10px;

          input {
            flex: 1 1 100%;
            height: 26px;
            box-sizing: border-box;
          }

          .confirm-skill-create {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            background: #7bc143;
            border: none;
            border-radius: 6px;
            cursor: pointer;

            mat-icon {
              cursor: pointer;
              font-size: 15px;
              width: 15px;
              height: 15px;
              line-height: 15px;
              color: white;
              font-weight: bold;
            }
          }
        }
      }

      .selected-type {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .new-type-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20px;
        background: #fefdf5;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        margin-bottom: 8px;

        span {
          font-weight: 500;
          font-size: 8px;
          line-height: 16px;
          letter-spacing: 0.1em;
          margin-top: 1px;
        }

        mat-icon {
          cursor: pointer;
          font-size: 10px;
          width: 10px;
          height: 10px;
          line-height: 10px;
        }
      }
    }
  }
}

// The alert banner for success/error msgs
#message-container {
  z-index: 2000;
}

// Overlay for loading spinner that obscures the entire page
.loading-spinner-overlay {
  z-index: 2001;
}

// hides feedback button on mobile devices
@media (max-width: 959px) {
  #feedbacklabel {
    z-index: 0 !important;
  }
}

/********************************************************************
                Global Styles, Frequently Used Classes
*********************************************************************/
body {
  color: #555;
  margin: 0;
  padding: 0;
  font-family: 'Asap';

  * {
    box-sizing: border-box;
  }
}

.page-container {
  height: 100%;
  flex-grow: 1;
  background-color: #faf7ed;
  margin-bottom: 0;
  width: 100%;
  overflow-y: auto;
}

main.content {
  padding: 10px;
}

.legacy-content {
  padding: 0 16px 100px;
}

@media (min-width: 960px) {
  .legacy-content {
    padding: 0 24px 100px;
  }
}

.mat-mdc-select-panel {
  &.icon-option-wrap {
    min-width: unset !important;
    transform: translateX(-1px) translateY(-8px) !important;

    .mat-mdc-option {
      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
      .mat-option-text {
        display: flex;
        justify-content: center;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.mat-icon {
  cursor: default;
}

.clicky {
  cursor: pointer;
}

.draggy {
  cursor: all-scroll;
}

.error-msg {
  font-weight: bold;
  color: #f45c54;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.flex-mobile-wrap {
  display: flex;
}

@media screen and (max-width: 700px) {
  .flex-mobile-wrap {
    flex-wrap: wrap;
  }
}

.oph-backdrop {
  background-color: $black-color-lighter;
}

.oph-dialog .mat-mdc-dialog-container {
  border-radius: 20px;
  padding: 0;
}

input,
select,
textarea,
option,
button {
  outline: none; // suppress new behavior in Chromium 83
}

input,
textarea {
  color: #4a443d;
}

button {
  cursor: pointer;
}

/*******************************************************************
                        Semantic Colors
********************************************************************/
.bg-success {
  background-color: $success-color-lightest;
}

.text-success {
  color: $legacy-success-color;
}

.mat-success {
  background-color: $legacy-success-color;
  color: #fff;
}

.bg-error {
  background-color: $error-color-faded;
}

.text-error {
  color: $legacy-error-color;
}

.mat-mdc-form-field-error {
  background-color: $legacy-error-color;
  color: #fff;
}

.bg-alert {
  background-color: $alert-color-faded;
}

.text-alert {
  color: $alert-color;
}

.mat-alert {
  background-color: $alert-color;
  color: #fff;
}

.bg-inventory {
  background-color: $inventory-selected;
}

.text-inventory {
  color: $inventory;
}

.mat-inventory {
  background-color: $inventory;
  color: #fff;
}

.bg-tasks {
  background-color: $tasks-selected;
}

.text-tasks {
  color: $tasks;
}

.mat-tasks {
  background-color: $tasks;
  color: #fff;
}

.bg-rules {
  background-color: $rules-selected;
}

.text-rules {
  color: $rules;
}

.mat-rules {
  background-color: $rules;
  color: #fff;
}

.bg-skeds {
  background-color: $skeds-selected;
}

.text-skeds {
  color: $skeds;
}

.mat-skeds {
  background-color: $skeds;
  color: #fff;
}

/*******************************************************************
                    Atomic Classes Margin/Padding
********************************************************************/
.mt {
  margin-top: 0.25rem;
}

.mr {
  margin-right: 0.25rem;
}

.mr-10 {
  margin-right: 10px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.ml {
  margin-left: 0.25rem;
}

.ml-4 {
  margin-left: 4px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pr-15 {
  padding-right: 15px;
}
/*******************************************************************
                              Modal
********************************************************************/
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: $modal-background-color;
  margin: 65px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
}

.modal-ctrl-btns {
  display: flex;
  justify-content: space-between;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/*******************************************************************
                        Simple Styled Table
********************************************************************/
.styled-table {
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ccc;
  }
}

.oph-table {
  padding: 0px 16px;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;

  tr:first-child {
    font-size: 12px;
    color: $black-color-light;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
  }

  tr {
    height: 48px;
  }

  td,
  th {
    border-bottom: 1px solid $black-color-lightest;
    padding: 8px;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ccc;
  }
}

/*******************************************************************
                            Mat Tabs
********************************************************************/

.oph-mat-tab-group {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label,
  .mat-tab-label-active {
    padding: 0px 4px;
    justify-content: flex-start;
    font-size: 16px;
    font-family: $bitter-font-family;
    font-weight: 700;
    color: $black-color;
    height: 36px;
    align-items: flex-start;
    background-color: transparent;
  }

  .mat-ink-bar {
    width: 24px !important;
    margin-left: 4px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 4px;
  }
}

/*******************************************************************
                        Custom Tooltip
********************************************************************/

.mdc-tooltip__surface {
  border-radius: 10px !important;
  font-family: Asap !important;
  background-color: #fff !important;
  color: var(--off-black) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 12px 18px !important;
}

/*******************************************************************
                        Slider
********************************************************************/

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-thumb-label {
  transform: rotate(45deg) translateY(-15px) translateX(-6px) !important;
  border-radius: 90% 90% 0 !important;
  height: 40px !important;
  width: 40px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-thumb {
  transform: scale(0.5) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-thumb-label-text {
  opacity: 1 !important;
  font-size: 12px;
}

/*******************************************************************
                        Color Palette Styles
********************************************************************/
.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-success {
  background-color: $success-color;
}

.bg-warning {
  background-color: $warning-color;
}

.bg-error {
  background-color: $error-color;
}

.bg-black {
  background-color: $black-color;
}

.bg-white {
  background-color: $white-color;
}

.border-primary {
  border-color: $primary-color;
}

.border-secondary {
  border-color: $secondary-color;
}

.border-success {
  border-color: $success-color;
}

.border-warning {
  border-color: $warning-color;
}

.border-error {
  border-color: $error-color;
}

.border-black {
  border-color: $black-color;
}

.border-white {
  border-color: $white-color;
}

.task-form-select {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow {
    opacity: 0;
  }
}

.task-form-user-chip {
  .mat-mdc-standard-chip {
    background-color: #f9fafb;
    color: rgba($black-color, 0.64);
    border: 1px solid #ededee;
    padding: 2px 8px 2px 3px !important;
  }
}

.task-form-role-chip {
  .mat-mdc-standard-chip {
    background-color: #f9fafb;
    color: rgba($black-color, 0.64);
    border: 1px solid #ededee;
    padding: 0 8px !important;
  }
}

.task-form-chapter-chip {
  .mat-mdc-standard-chip {
    padding: 0 8px !important;
    border-radius: 8px;
  }
}

.oph-brown-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #5d4037 !important;
  }
}

.oph-orange-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: $primary-color !important;
  }
}

.oph-yellow-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #fded8b !important;
  }
}

.oph-white-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: white;
  }
}

.oph-grey-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #e5e3dc;
  }
}

.oph-dark-grey-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #a3a099;
  }
}

.oph-green-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #7bc143;
  }
}

.body-wrap {
  .task-name-input {
    overflow: visible !important;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      background: white;
      max-height: 33px !important;
      height: 100%;
      margin-top: 12px !important;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        padding: 0.5rem 0 !important;
      }
    }
  }
}

.oph-general-dialog .mat-mdc-dialog-container {
  border-radius: 20px;
  background-color: #faf7ed;
}

.list-views-warning-dialog .mat-mdc-dialog-container {
  border-radius: 6px;
  width: 536px;
}

.brown-checkbox-container {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #5d4037 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: #5d4037 !important;
  }
}

.schedule-project-calendar {
  border: 1px solid #e2ded5;
  border-radius: 16px;
  min-height: 338px;

  .mat-calendar-body-disabled {
    opacity: 0.3;
  }
}

.oph-orange-spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: $primary-color;
  }
}

.list-view-menu {
  min-width: unset !important;
  border-radius: 6px;
  min-height: 1px !important;
  max-width: 100% !important;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

.oph-select-menu {
  min-width: unset !important;
  border-radius: 12px !important;
  min-height: 1px !important;
  max-height: calc(50vh - 20px);
  max-width: none !important;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

.oph-select-menu-item {
  all: unset;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 464px;
  padding: 8px 18px;
  border-bottom: 1px solid #e5e3dc;
  line-height: 20px;
  box-sizing: border-box;
  min-width: 100px;
  min-height: 50px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.oph-select-item-no-padding {
  padding: 0 !important;
}

.expand-menu-header {
  padding: 0 !important;
}

.goals-tracker-menu {
  max-height: 300px !important;
}

.goals-tracker-menu-item {
  padding: 9.5px 0;
  min-height: 0;
  font-size: 14px;
}

.goals-tracker-menu-sub-item {
  border-bottom: none;
  height: 30px;
  padding: 0 20px;
  min-height: 0;
  font-size: 14px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.schedule-menu {
  border-radius: 6px !important;
  width: 175px !important;

  .mat-mdc-menu-content {
    padding: 0 !important;

    button {
      background-color: #ffffff;
      border-bottom: 1px solid #e5e3dc;

      span {
        span {
          color: #7c655f;
        }
      }

      &:hover {
        background-color: #f1f1ee;
      }
    }

    button:last-child {
      border-bottom: none;
    }
  }

  @media screen and (min-width: 1066px) {
    width: 273px !important;
  }
}

.oph-selector-menu {
  min-width: unset;
  border-radius: 8px !important;
  min-height: 1px !important;
  max-height: calc(50vh - 20px) !important;
  max-width: none !important;

  .mat-mdc-menu-content {
    padding: 0;
    width: 100% !important;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-mdc-menu-panel {
    max-width: 100% !important;
  }
}

.task-action-menu {
  min-width: unset;
  border-radius: 12px !important;
  min-height: 1px !important;
  max-height: calc(50vh - 20px);
  max-width: 229px !important;
  border: 1px solid var(--Ophanim-Final-Border, #e5e3dc);

  .mat-mdc-menu-item {
    min-width: 229px !important;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

.legacy-dialog {
  .mat-mdc-dialog-surface {
    padding: 20px;
  }
}

.buttons-container {
  display: flex;
  gap: 7px;
}

.buttons-container-end {
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}

// custom colors for mat-spinner
.custom-color-brown circle {
  stroke: var(--brown-med);
}

.custom-color circle {
  stroke: white;
}
.outline.brown .custom-color circle {
  stroke: var(--brown-med);
}
.outline.brownDark .custom-color circle {
  stroke: var(--dark);
}
.outline.disabled .custom-color circle {
  stroke: #e5e3dc;
}
.outline.green .custom-color circle {
  stroke: #7bc143;
}
.outline.greenDark .custom-color circle {
  stroke: #64aa2c;
}
.outline.orange .custom-color circle {
  stroke: #f57b4e;
}
.outline.orangeDark .custom-color circle {
  stroke: #e05d2d;
}
.outline.gray .custom-color circle {
  stroke: var(--grey-med);
}

.current-sked-progress-imperative {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #fc5555;
  }
}

.current-sked-progress-high {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #ff9f2d;
  }
}

.current-sked-progress-normal {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #5fe8af;
  }
}

.current-sked-progress-low {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #5c95e9;
  }
}

.current-sked-progress- {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #7c655f;
  }
}

.progress-container {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(--blue);
  }
}

.oph-loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.color-select-container {
  background-color: #fefdf5;
  border-radius: 16px !important;
  padding: 10px;
  width: 680px;
  height: 303px;
  max-width: none !important;
  max-height: none !important;

  .inner-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    border: 1px solid #e5e3dc;
    border-radius: 12px;
    height: 100%;
    padding: 9px;

    .color-block {
      width: 55px;
      height: 55px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

// edit menu controls
.actions-menu-container {
  padding: 0 4px 0 16px;
  background-color: #faf7ed;
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.actions-menu-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon {
    cursor: pointer;
  }
}

.hide-menu-button {
  width: 40px;
  height: 40px;
  background: #f0ecdd;
  border-radius: 8px;
}
.mat-mdc-icon-button:hover {
  background-color: #fefdf5;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.project-mat-tab-group {
  .mdc-tab__text-label {
    font-size: 14px;
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--off-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--orange) !important;
    border-radius: 2px 2px 0 0 !important;
  }

  .mdc-tab-indicator__content {
    color: #eb7a3b !important;
    border-radius: 2px 2px 0 0 !important;
  }

  .mdc-tab-indicator__content--underline {
    color: #eb7a3b !important;
    border-bottom: 1px solid #2f27231f;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid #2f27231f;
    --mdc-tab-indicator-active-indicator-height: 3px;
  }
}

//Quill rich text editor
.ql-editor {
  height: 96%;
}

.current-sked-quill {
  .ql-editor {
    height: auto;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: #a3a099;
}

.ql-container {
  max-height: 92% !important;
  font-size: 16px !important;
}

.quill-caption {
  font-size: 12px;
  font-style: italic;
}

.quill-progress-wrapper {
  margin-top: -30px !important;

  .quill-progress {
    background-color: var(--orange);
  }
}

// Task calendar
.twd-calendar,
.task-calendar {
  .mat-calendar-table-header-divider::after {
    background: none;
  }

  .mat-calendar-body-label {
    visibility: hidden;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-table {
    .mat-calendar-body {
      tr {
        &[aria-hidden='true'] {
          display: none;
        }

        .mat-calendar-table-header th {
          background: red !important;
        }

        td {
          padding-top: 30px !important;
          padding-bottom: 9px !important;
          color: #5d4037;

          &[aria-hidden='true'] {
            content-visibility: hidden;
          }

          .mat-calendar-body-cell {
            .mat-calendar-body-cell-content {
              background: #fefdf5;
              width: 49px;
              height: 30px;
              left: calc(50% - 25px);
              color: #5d4037;
              border-radius: 8px;
              font-size: 16px !important;
            }

            .mat-calendar-body-selected {
              background: #7c655f;
              color: white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .mat-calendar-body-cell {
    .mat-calendar-body-cell-content {
      width: 30px !important;
      height: 25px !important;
      left: calc(50% - 16px) !important;
    }
  }
}

// TASK TIMEPICKER
@mixin taskTimepickerStyles {
  .timepicker-ui-wrapper,
  .timepicker-ui-wrapper.mobile {
    .timepicker-ui-header {
      flex-direction: row !important;
    }

    box-shadow: none !important;
    background-color: #faf7ed !important;
    border-radius: 12px;
    // width: 100vw !important;
    // max-width: 720px !important;
    // height: 100% !important;
    // max-height: 706px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;

    .timepicker-ui-hour,
    .timepicker-ui-minutes,
    .timepicker-ui-wrapper-type-time {
      border: 1px solid var(--Ophanim-Final-Border, #e5e3dc) !important;
      background-color: var(--Ophanim-Final-lightest, #fefdf5) !important;
      color: var(--Ophanim-Final-brown-med, #7c655f);
      font-family: Asap;
    }

    .timepicker-ui-hour,
    .timepicker-ui-minutes {
      width: 70px;
      height: 49px;
      font-size: 28px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15px;
    }

    .timepicker-ui-wrapper-type-time {
      width: 45px;
      height: 50px;
    }

    .timepicker-ui-dots,
    .timepicker-ui-dots.mobile {
      font-size: 30px;
      height: 10px;
    }

    .timepicker-ui-hour-time-12,
    .timepicker-ui-minutes-time {
      font-family: Asap;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.15px;
      height: 30px;
      width: 30px;
    }

    .timepicker-ui-circle-hand {
      height: 30px;
      width: 30px;
    }

    .timepicker-ui-clock-face {
      background: #fefdf5 !important;
      border: 1px solid var(--Ophanim-Final-Border, #e5e3dc);
    }
  }

  .timepicker-ui-cancel-btn,
  .timepicker-ui-cancel-btn.mobile {
    color: var(--Ophanim-Final-brown-med, #7c655f) !important;
    font-family: Asap;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background-color: transparent;
    }
  }

  .timepicker-ui-ok-btn,
  .timepicker-ui-ok-btn.mobile {
    background: var(--Ophanim-Final-green, #7bc143) !important;
    color: var(--Ophanim-Final-light, #faf7ed);
    font-family: Asap;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px !important;
    width: 102px;
    margin-left: 10px;

    &:hover {
      background: #64aa2c !important;
    }
  }
}

// This is a workaround to prevent TimepickerUI from automatically going landscape and
// breaking the layout.
@media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
  .timepicker-ui-wrapper:not(.timepicker-ui-wrapper + .mobile) {
    flex-direction: column;
    height: 500px !important;
    width: auto;
  }

  .timepicker-ui-wrapper-type-time {
    flex-direction: column !important;
    width: 100%;
    margin-left: 10px !important;
  }

  .timepicker-ui-body {
    margin-top: -20px !important;
  }

  .task-dialog-timepicker {
    .timepicker-ui-wrapper:not(.timepicker-ui-wrapper + .mobile) {
      height: 1000px !important;
    }
  }
}

.edit-sked-timepicker {
  @include taskTimepickerStyles;
  .timepicker-ui-wrapper,
  .timepicker-ui-wrapper.mobile {
    max-width: 680px;
    max-height: 691px;
  }
}

@media (max-width: $task-dialog-mobile-breakpoint) {
  .task-dialog-timepicker {
    .timepicker-ui-wrapper {
      max-width: 100% !important;
      max-height: 100vw !important;
      height: 100% !important;
    }
  }

  .task-wizard-timepicker {
    .timepicker-ui-wrapper:not(.timepicker-ui-wrapper + .mobile) {
      height: calc(100% - 42px) !important;
    }
  }
}

.task-dialog-timepicker {
  @include taskTimepickerStyles;
  .timepicker-ui-wrapper {
    height: 100%;
    width: 100vw !important;
    max-width: 681px !important;
    max-height: 691px !important;
  }

  .timepicker-ui-wrapper.mobile {
    max-width: 681px;
    max-height: 691px;
  }
}

.task-wizard-timepicker {
  @include taskTimepickerStyles;
  .timepicker-ui-wrapper,
  .timepicker-ui-wrapper.mobile {
    max-width: 680px;
    max-height: 691px;
  }
}

.task-dialog-timepicker .timepicker-ui-wrapper,
.task-dialog-timepicker .timepicker-ui-wrapper.mobile {
  max-width: 100%;
  max-height: 100%;
}

.task-wizard-timepicker .timepicker-ui-wrapper,
.task-wizard-timepicker .timepicker-ui-wrapper.mobile {
  max-width: 100%;
  max-height: 100%;
}

// Task slider
.task-slider-menu {
  min-width: unset;
  border-radius: 12px !important;
  min-height: 1px !important;
  max-height: calc(50vh - 20px);
  max-width: none !important;
  box-shadow: none !important;
  background: var(--Ophanim-Final-lightest, #fefdf5);
  border: 1px solid var(--Ophanim-Final-Border, #e5e3dc);

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
}

.mdc-slider {
  max-width: none !important;
}

.mdc-slider .mdc-slider__value-indicator-container {
  white-space: nowrap;
}

//TASK WIZARD
.twd-panel-title {
  color: var(--Ophanim-Final-Off-black, #4a443d);
  font-family: Asap;
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
}

.twd-calendar-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

//TASK DIALOG SLIDER
.task-slider-menu {
  .mat-mdc-slider {
    width: 100%;
  }
}

.oph-schedule-menu-title {
  padding: 10px;
  background: var(--tan-med);
  width: 100%;
}
